/* -- Methods -- */
const findBreakpoint = (width) => {
    if (width >= 980)
        return 'desktop';
    if (width >= 660)
        return 'tablet';
    return 'mobile';
};
const getViewportTargeting = ({ viewPortWidth, cmpBannerWillShow, }) => {
    // Don’t show inskin if if a privacy message will be shown or on preview
    const isPreview = window.guardian.config.page.isPreview;
    const inskin = cmpBannerWillShow || isPreview ? 'f' : 't';
    return {
        bp: findBreakpoint(viewPortWidth),
        skinsize: viewPortWidth >= 1560 ? 'l' : 's',
        inskin,
    };
};
export { getViewportTargeting };
